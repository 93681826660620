import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}


export const InfoModal = ({ isOpen, handleClose }: Props) => {


  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word. Words increase in difficulty
	throughout the week starting on Sunday. 
      </p>
	<br />
      <p className="text-sm text-left text-gray-500 dark:text-gray-300">
	Rules:<br />
	<li>Common words are earlier in the week</li>
	<li>Apostrophes are omitted, e.g.: D'vana is "DVANA"</li>
	<li>Klingon words use the <a href="https://www.kli.org" className="underline font-bold">KLI</a> spelling</li>
	<li>Got a suggestion? Drop us a line at the <a href="https://discord.gg/xRSQSTNW6d" className="underline font-bold">Discord</a></li>
      </p> 
	<br />
      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Q" status="correct" />
        <Cell value="A" />
        <Cell value="P" />
        <Cell value="L" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter Q is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />
        <Cell value="I" />
        <Cell value="L" status="present" />
        <Cell value="O" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter L is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="V" />
        <Cell value="O" />
        <Cell value="R" />
        <Cell value="T" status="absent" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter T is not in the word in any spot.
      </p>
    </BaseModal>
  )
}
