import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  // DST patch
  //const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  //const now = Date.now()
  //const msInDay = 86400000
  //const index = Math.floor((now - epochMs) / msInDay)
  //const nextday = (index + 1) * msInDay + epochMs
  const epoch = new Date(2022, 0)
  const start = new Date(epoch)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  let index = 0
  while (start < today) {
    index++
    start.setDate(start.getDate() + 1)
  }

  const nextDay = new Date(today)
  nextDay.setDate(today.getDate() + 1)

  return {
    solution: WORDS[index % WORDS.length].toUpperCase(),
    solutionIndex: index,
    tomorrow: nextDay.valueOf(),
  }
}

export const { solution, solutionIndex, tomorrow } = getWordOfDay()
export const currentDay = () => {
	const d = new Date()
	let day = d.getDay()
	return day
}
export const calcCurrentRank = () => {
	var rank = 'Cadet'
	var day = currentDay()
	switch(day.toString()) {
		case '0': {
			rank = '🔘'
			break;
		}
		case '1': {
			rank = '🔘'
			break;
		}
		case '2': {
			rank = '🔘🔘'
			break;
		}
		case '3': {
			rank = '🔘🔘'
			break;
		}
		case '4': {
			rank = '🔘🔘🔘'
			break;
		}
		case '5': {
			rank = '🔘🔘🔘'
			break;
		}
		case '6': {
			rank = '🔘🔘🔘🔘'
			break;
		}
		default : {
			rank = 'Cadet'
			break;
		}
	}
	// override until 5/23
	rank = '🔘🔘🔘🔘'
	return rank
}

export const calcCurrentRankName = () => {
	var rank = 'Cadet'
	var day = currentDay()
	switch(day.toString()) {
		case '0': {
			rank = 'Ensign'
			break;
		}
		case '1': {
			rank = 'Ensign'
			break;
		}
		case '2': {
			rank = 'Lieutenant'
			break;
		}
		case '3': {
			rank = 'Lieutenant'
			break;
		}
		case '4': {
			rank = 'Commander'
			break;
		}
		case '5': {
			rank = 'Commander'
			break;
		}
		case '6': {
			rank = 'Captain'
			break;
		}
		default : {
			rank = 'Cadet'
			break;
		}
	}
	return rank
}
export const currentRank = calcCurrentRank()
export const currentRankName = calcCurrentRankName()

export const calcCurrentWeek = () => {
	// adapted from yunandtidus from Stackoverflow
	var currentDate = new Date()
	var currentYear = currentDate.getFullYear()
	var tempDate = new Date()
	tempDate.setHours(0,0,0,0)
	tempDate.setMonth(0)
	tempDate.setFullYear(currentYear)
	tempDate.setDate(1)
	var totalSundays = 0
	for (var i = tempDate; i <= currentDate; ) {
		if (i.getDay() === 0) {
			totalSundays++
		}
		i.setTime(i.getTime() + 1000*60*60*24)
	}

	if (totalSundays > 38) {
		return 38
	}
	else {
		return totalSundays;
	}
}
export const currentWeek = calcCurrentWeek()
