export const WORDS = [
	"nivar",
	"chair",
	"boost",
	"ships",
	"uhura",
	"tyler", 
	"delta",
	"qapla",
	"float",
	"medic",
	"prime",
	"phase",
	"shran",
	"phage",
	"sukal",
	"flare",
	"genes",
	"alien",
	"earth",
	"power",
	"loops",
	"human",
	"turbo",
	"fleet",
	"ready",
	"chief",
	"world",
	"light",
	"moral",
	"pilot",
	"party",
	"canon",
	"field",
	"cargo",
	"sense",
	"dvana",
	"noted",
	"films",
	"phlox",
	"garak",
	"wrath",
	"drone",
	"orbit",
	"queen",
	"honor",
	"drive",
	"xindi",
	"harry",
	"sarek",
	"probe",
	"lower",
	"range",
	"orion",
	"miles",
	"braga",
	"kazon",
	"bandi",
	"space",
	"talks",
	"refit",
	"fired",
	"seven",
	"tarka",
	"mokai",
	"shoot",
	"stars",
	"beams",
	"gamma",
	"elnor",
	"duras",
	"janus",
	"armed",
	"craft",
	"bajor",
	"scans",
	"laris", 
	"faith",
	"moore", 
	"cadet",
	"panel",
	"array",
	"decks",
	"hoshi",
	"soran",
	"rizzo",
	"nurse",
	"virus",
	"hails",
	"solar",
	"soong",
	"molly",
	"okuda",
	"nerve",
	"agent",
	"relay",
	"ranks",
	"damar",
	"seska",
	"quinn",
	"robot",
	"logic",
	"bolts",
	"talos",
	"rigel",
	"terok",
	"silik",
	"crash",
	"scout",
	"cloak",
	"nexus",
	"gomez",
	"tendi",
	"malon",
	"alert",
	"races",
	"clone",
	"reman",
	"vedek",
	"agnes",
	"cygna",
	"shock",
	"truth",
	"adapt",
	"terra",
	"kruge",
	"icheb",
	"degra",
	"storm",
	"brave",
	"whale",
	"flyer",
	"chang",
	"sloan",
	"ishka",
	"trade",
	"chart",
	"poker",
	"bones",
	"soval",
	"ziyal",
	"kelly",
	"route",
	"waves",
	"nimoy", 
	"moons",
	"carol", 
	"ajala",
	"combs",
	"metal",
	"union",
	"titan",
	"visor",
	"naomi",
	"angel", 
	"danar",
	"pulse",
	"raise",
	"lunar",
	"pavel",
	"sybok",
	"vorta",
	"korby",
	"vital",
	"grade",
	"comet",
	"singh",
	"brunt",
	"laren", 
	"surak",
	"crews",
	"reply",
	"milky",
	"sonic",
	"sigma",
	"leeta",
	"deneb",
	"steel",
	"stuns",
	"flute",
	"servo",
	"armin",
	"avery",
	"miral",
	"armor",
	"blast",
	"padds",
	"warps",
	"spore",
	"edith",
	"lursa",
	"hatch",
	"judge",
	"hulls",
	"ports",
	"krall",
	"nagus",
	"malik",
	"burst",
	"shift",
	"flown",
	"havoc",
	"armus",
	"macos",
	"koval",
	"shirt",
	"rifle",
	"spock",
	"alloy",
	"vorik",
	"opaka",
	"joran",
	"swarm",
	"tubes",
	"mccoy",
	"alpha",
	"aenar",
	"theta",
	"trabe",
	"trace",
	"chess",
	"emits",
	"atoms",
	"inner",
	"horta",
	"soren",
	"laser",
	"glory",
	"pylon",
	"lobes",
	"moset",
	"terry",
	"toral",
	"alarm",
	"aimed",
	"venus",
	"serum",
	"shiar",
	"majel",
	"rayna",
	"wreck",
	"vents",
	"remus",
	"raven",
	"iotia",
	"azati",
	"thrax",
	"tasks",
	"badge",
	"coils",
	"dyson",
	"empok",
	"tuvix",
	"bynar",
	"depot",
	"cable",
	"beeps",
	"solid",
	"nyota",
	"hardy",
	"ogawa",
	"sweep",
	"shaft",
	"sisko",
	"raffi",
	"shaxs",
	"adira",
	"voles",
	"bases",
	"wires",
	"major",
	"scott",
	"narek",
	"lcars",
	"chong", 
	"lifts",
	"rerun",
	"james",
	"katra",
	"oomox",
	"keyla",
	"dukat",
	"synth",
	"brain", 
	"pinch",
	"belay",
	"david",
	"chaos",
	"april",
	"movie",
	"heart",
	"ionic",
	"cubes",
	"breen",
	"cabal",
	"ardra",
	"binge",
	"scifi",
	"devil", 
	"patch",
	"golem",
	"shaka",
	"kivas", 
	"ethic",
	"games",
	"codes",
	"demon",
	"sioux", 
	"mount",
	"sonak",
	"peace",
	"merit",
	"corps", 
	"cabin", 
	"opera",
	"lrell",
	"yamok",
	"retro", 
	"evade",
	"cameo",
	"squad", 
	"mercy", 
	"disco", 
	"linus",
	"theme",
	"wired",
	"decal",
	"andor",
	"ritos",
	"trina",
	"palor",
	"final",
	"campy",
	"elite",
	"adami",
	"akira",
	"anton",
	"petaq",
	"dwarf",
	"dress",
	"comms",
	"toxin",
	"renee",
	"boday",
	"zetar",
	"epoch",
	"clamp",
	"needs", 
	"quark",
	"temba",
	"akiva",
	"stonn", 
	"abort",
	"class",
	"trill",
	"riker",
	"qonos",
	"tilly",
	"nella",
	"unity",
	"trust",
	"ethos",
	"paris",
	"carey",
	"dixon",
	"diana",
	"death",
	"enemy",
	"tuvok",
	"keiko",
	"nerys",
	"davis",
	"jovis",
	"facts",
	"seals", 
	"sonya",
	"valor",
	"lorca",
	"sagan",
	"komar",
	"nodes",
	"nukes",
	"chute",
	"strut",
	"tasha",
	"ethan",
	"argus",
	"tools",
	"speed",
	"blood",
	"model",
	"biome",
	"alamo",
	"korax",
	"lands",
	"skill",
	"slime",
	"midas",
	"vance",
	"tawny",
	"xahea",
	"toxic",
	"takei",
	"brent",
	"levar",
	"anson",
	"gates",
	"okmyx",
	"henry",
	"satie",
	"talax",
	"kanar",
	"targs",
	"selar",
	"jumja",
	"bokai",
	"hutch",
	"thyla",
	"lumba",
	"chula",
	"daren",
	"cabot",
	"jolan",
	"robau",
	"ptera",
	"tanis",
	"soyuz",
	"wells",
	"kavok",
	"sovak",
	"irene",
	"kirok",
	"forra",
	"gegen",
	"dahar",
	"emory",
	"brock",
	"lanel",
	"douwd",
	"balok",
	"antos",
	"angus",
	"atrea",
	"benny",
	"betor",
	"bruce",
	"bryce",
	"couts",
	"deleb",
	"dolim",
	"galor",
	"hirai",
	"jalad",
	"jarok",
	"kmpec",
	"kmtar",
	"kolos",
	"laira",
	"leola",
	"lycia",
	"maltz",
	"maman",
	"masks",
	"ndoye",
	"okona",
	"omega",
	"risan",
	"sarin",
	"snare",
	"tpera",
	"uthat",
	"kelby",
	"clara",
	"killy",
	"brott",
	"slara",
]
