import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        This is a Star Trek themed version of the popular game Wordle, the open source code is -{' '}
        <a
          href="https://github.com/hannahcode/GAME"
          className="underline font-bold"
        >
          here
        </a>{' '}
      </p>
      <br />
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Idea and graphics for Trekle came from <a href="https://twitter.com/aarondavidlewis" className="underline font-bold">@aarondavidlewis</a>
      </p>
      <br />
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Hosting and some code updates done by <a href="https://twitter.com/zakarth" className="underline font-bold">@zakarth</a>
      </p>
      <br />
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Are you a recovering Star Trek nerd?  Have a suggestion?  Join us on the Star Trek: Discorderly <a href="https://discord.gg/xRSQSTNW6d" className="underline font-bold">Discord</a>.
      </p>
    </BaseModal>
  )
}
