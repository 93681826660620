import Countdown from 'react-countdown'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import { currentWeek } from '../../lib/words'
import domtoimage from 'dom-to-image'
import { saveAs } from 'file-saver'
import AWS from 'aws-sdk'
import { v4 as uuidv4 } from 'uuid'
import ReactGA from 'react-ga4'

import {
  TRACKING_ID,
  NEW_WORD_TEXT,
} from '../../constants/strings'

type Props = {
  submitted: string
  isOpen: boolean
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleClose: () => void
  handleShare: () => void
  handleStats: () => void
  handleCarousel: () => void
  handleSubmit: () => void
}

//export const currentYear = new Date().getFullYear()
export const currentYear = 2022
//var selectedWeek:number = currentWeek
var selectedWeek:number = 37

const S3_BUCKET = 'treklegame.com'
const REGION = 'us-east-1'


AWS.config = new AWS.Config();
AWS.config.accessKeyId = "AKIAQXTSBVBVPZJDX3OW";
AWS.config.secretAccessKey = "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";

const trekBucket = new AWS.S3({
	params: { Bucket: S3_BUCKET},
	region: REGION,
})

ReactGA.initialize(TRACKING_ID)

export const ToonModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
  handleCarousel,
  handleStats,
  handleSubmit,
  submitted,
}: Props) => {


  async function getWinnerCaption() {
		var caption:string
	try {
		let response = await fetch('/toons/winners/winner' + String(currentYear) + String(selectedWeek) + '.json');
		let responseJson = await response.json();
		caption = responseJson.caption as string
		if (caption === "") {
			caption = "There is no caption";
			(document.getElementById("winnerText") as HTMLInputElement)!.classList.add('hidden');
		}
		(document.getElementById("winnerText") as HTMLInputElement)!.classList.remove('hidden');
		return caption;
	} catch(error) {
		(document.getElementById("winnerText") as HTMLInputElement)!.classList.add('hidden');
	}
	return ""
  }

  function getFinalCaption() {
	getWinnerCaption().then((value) => {
		const str: string = value;
		let d = (document.getElementById("winnerText") as HTMLInputElement);
		d!.innerHTML = "";
		d!.innerHTML = str;
		return str
	});
	return ""
  }
  
  function saveToon() {
	ReactGA.event({category: 'Event', action: 'Saved toon ' + Number(selectedWeek) + ' locally'})
	const node = document.getElementById('sharetoon') as HTMLCanvasElement

	domtoimage.toBlob(node, {
		width: node.clientWidth * 2,
		height: node.clientHeight * 2,
		style: {
			transform: 'scale('+2+')',
			transformOrigin: 'top left'
		}
	}).then(blob => {
		domtoimage.toBlob(node, {
			width: node.clientWidth * 2,
			height: node.clientHeight * 2,
			style: {
				transform: 'scale('+2+')',
				transformOrigin: 'top left'
			}
		})
		.then(function(blob) {
		  saveAs(blob, 'trektoon-week' + Number(selectedWeek) + '.png');
		});
	})
  }

  function submitToon() {
		const text = (document!.getElementById('captionText') as HTMLInputElement).value

		if (text === "") {
			return
		}
		const params = {
			Body: text,
			Bucket: S3_BUCKET,
			Key: 'submissions/sub-' + String(currentWeek) + '-' + String(uuidv4()) + '.txt'
		}
	

		trekBucket.putObject(params)
			.send((err) => {
				if (err) console.log(err)
		})
	return
  }
  return (
    <BaseModal
      title={ (isGameLost || isGameWon) ? "Share Your Trekle Score" : "Share Your Caption" }
      isOpen={isOpen}
      handleClose={handleClose}
   >  
      {(isGameLost || isGameWon) && (
        <div className="mt-1 sm:mt-1 dark:text-white grid grid-cols-3 gap-2">
          <div className="">
            <h5>{NEW_WORD_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
            />
          </div>
          <button
            type="button"
            className="w-full mt-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
            onClick={() => {
	      handleStats()
            }}
          >
            Stats
          </button>
          <button
            type="button"
            className="w-full mt-2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
            onClick={() => {
              shareStatus(guesses, isGameLost)
	      handleShare()
            }}
          >
            Share
          </button>
	<hr 
		className="col-span-3"
	/>
        </div>
      )}
	<h5
		className="my-2 text-md leading-6 font-medium text-gray-900 dark:text-gray-100" 
		id="headlessui-dialog-title-25">
			Warpt Comic Weekly Caption Contest
	</h5>
	<div id="sharetoon" className="relative bg-stone-200 w-full rounded-md content-center">
		<img 
			id="toonscreen" 
			className="w-full border-4 border-stone-200 h-95" src={"/toons/toon" + String(currentYear) + String(selectedWeek) + ".png"} 
			alt="Selected Toon"
			onError={({ currentTarget }) => {
				currentTarget.onerror = null; // prevents looping
				currentTarget.src="/toons/error.jpg";
			}}
		/>
		<div className="pt-0">
			<textarea 
				id="captionText"
				maxLength={500}
				className={`placeholder:italic mb-5 bg-stone-200 placeholder-gray-500 align-middle px-2 overflow-hidden resize-none absolute content-center inset-x-0 bottom-0 border border-2 border-transparent bg-white h-16 text-center align-text-center text-sm w-full ${selectedWeek >= 38 ? "inline-block" : "hidden"}`}
				placeholder={ selectedWeek >= 38 ? "Enter your caption here. Join the Discord to see other submissions and vote." : "" }
				rows={2}
				onChange={() => console.log('')}
			/>
		</div>
		<div 
				id="winnerText"
				className={`align-middle mb-5 bg-stone-200 placeholder-gray-500 align-middle px-2 overflow-hidden resize-none absolute content-center inset-x-0 bottom-0 border border-2 border-transparent bg-white h-16 text-center align-text-center text-sm ${selectedWeek >= 38 ? "hidden" : "inline-block"}`}
		>
				{ selectedWeek >= 38 ? "" : getFinalCaption() }
		</div>
		<div 
			className={`absolute text-xs h-5 bottom-0 right-2 align-text-bottom ${selectedWeek === currentWeek ? "visible" : ""}`}
		>
			{ selectedWeek >= 38 ? "treklegame.com" : "#" + String(selectedWeek) }
		</div>
	</div>
        <div className="mt-1 sm:mt-1 dark:text-white grid grid-cols-4 gap-1">
	  <button 
	     type="button" 
	     className={`mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-xs ${ selectedWeek >= 38 ? "bg-green-600 hover:bg-green-700 focus:ring-green-500" : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"}`}
	     onClick={() => {
		if (selectedWeek > 1) {
			selectedWeek = selectedWeek - 1
		}
		handleCarousel()
	    }}
	  >
		{ selectedWeek >= 38 ? "Winners" : "Previous" }
	  </button>
	   <button
            type="button" 
            className={`mt-2 w-25 rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm`}
            onClick={() => {
	      saveToon()
            }}
          >
            Save
          </button>
	   <button
            type="button" 
            className={`mt-2 w-25 rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none align-text-bottom focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm ${selectedWeek >= 38 ? "visible" : "invisible"}`}
            onClick={() => {
		if (submitted==="no") {
		      submitToon()
		}
	      handleSubmit()
            }}
          >
            Submit
          </button>
	  <button 
	     type="button" 
	     className={`mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-sm font-medium text-white focus:outline-none sm:text-xs ${selectedWeek >= 37 ? "disabled bg-slate-500" : "bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}`}
	     onClick={() => {
		if (selectedWeek < 37) {
			selectedWeek = selectedWeek + 1
		}
		handleCarousel()
	    }}
	  >
		Next
	  </button>
      </div>
    </BaseModal>
  )
}
