import {
  SunIcon,
  MoonIcon,
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { ToonModal } from './components/modals/ToonModal'
import {
  TRACKING_ID,
  GAME_TITLE,
  GAME_LOGO,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  ABOUT_GAME_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  FAIL_MESSAGE,
  CORRECT_WORD_MESSAGE,
} from './constants/strings'
import {
  MAX_WORD_LENGTH,
  MAX_CHALLENGES,
  ALERT_TIME_MS,
} from './constants/settings'
import { isWordInWordList, isWinningWord, solution, currentWeek } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
} from './lib/localStorage'
import ReactGA from 'react-ga4'
import './App.css'


function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const discordUrl = 'https://discord.gg/xRSQSTNW6d'
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isMoreThanOnce, setIsMoreThanOnce] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isToonModalOpen, setIsToonModalOpen] = useState(false)
  const [isJustSubmitted, setIsJustSubmitted] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [successAlert, setSuccessAlert] = useState('')
  const [selectedWeek, setSelectedWeek] = useState(currentWeek)
  const [submitted, setSubmitted] = useState(() => {
    const loaded = loadGameStateFromLocalStorage()

    if (loaded?.submitted !== String(currentWeek)) {
	return "no"
    }
    return loaded.submitted
  })

  ReactGA.initialize(TRACKING_ID)

  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
      ReactGA.event({category: 'Event', action: 'Won daily Trekle'})
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      ReactGA.event({category: 'Event', action: 'Lost Daily Trekle'})
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    ReactGA.send("pageview");
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const handleDarkMode = (isDark: boolean) => {
    ReactGA.event({category: 'Button', action: 'Switched to Dark Mode'})
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution, submitted })
  }, [guesses, submitted])


  useEffect(() => {
    ReactGA.event({category: 'User', action: 'Launched Trekle'})
    if (isJustSubmitted) {
	const submitData = "Submitted to Discord. Join <a class=\"underline font-bold\" target=\"_blank\" href=\"https://discord.gg/pPcuRRxzKB\">here</a> to see votes."
	setSuccessAlert(submitData)
	setTimeout(() => { 
		setSuccessAlert('')
		setIsJustSubmitted(false)
	}, 6000)
	return
    } 
    if (isGameWon) {
      setSuccessAlert(
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      )
      setTimeout(() => {
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon, isGameLost, isJustSubmitted])


  const onChar = (value: string) => {
    if (
      currentGuess.length < MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon && !isToonModalOpen
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }
    if (!(currentGuess.length === MAX_WORD_LENGTH)) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    const winningWord = isWinningWord(currentGuess)

    if (
      currentGuess.length === MAX_WORD_LENGTH &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        ReactGA.event({category: 'Event', action: 'Won daily Trekle'})
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        ReactGA.event({category: 'Event', action: 'Lost daily Trekle'})
        setIsGameLost(true)
      }
    }
  }
  
  return (
    <div className="pt-2 pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-6 mt-1">
        <div className="text-xl ml-2.5 grow font-bold dark:text-white">
          <img src={GAME_LOGO} title={GAME_TITLE} alt={GAME_TITLE} width="189px" className="logo-fix" />
        </div>
        {isDarkMode ? (
          <SunIcon
            className="ml-2 h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => handleDarkMode(!isDarkMode)}
          />
        ) : (
          <MoonIcon 
            className="ml-2 h-6 w-6 mr-2 cursor-pointer"
            onClick={() => handleDarkMode(!isDarkMode)}
          />
        )}
        <img src="comm.svg" alt="How to play" width="24px" title="How to play"
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => { 
		setIsInfoModalOpen(true)
	        ReactGA.event({category: 'Modal', action: 'Info Modal Opened'})
	  }}
        />
        <img src="borg.svg" alt="Crew Performance" width="24px" title="Crew Performance"
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => { 
			setIsStatsModalOpen(true) 
		        ReactGA.event({category: 'Modal', action: 'Stats Modal Opened'})
		}}
        />
        <img src="warpt.svg" alt="Warpt Comic" width="24px" title="Warpt Comic"
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => { setIsToonModalOpen(true)
		        ReactGA.event({category: 'Modal', action: 'Toon Modal Opened'})
	  }}
        />
      </div>
      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard
        onChar={onChar}
        onDelete={onDelete}
        onEnter={onEnter}
        guesses={guesses}
      />
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => { 
		setIsStatsModalOpen(false)
	}}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          ReactGA.event({category: 'Button', action: 'Hit the share button'})
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <ToonModal
        isOpen={isToonModalOpen}
        handleClose={() => setIsToonModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        submitted={submitted}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
	handleCarousel={() => {
          ReactGA.event({category: 'Button', action: 'Browsed toons'})
          setSelectedWeek(selectedWeek - 1)
	}}
        handleStats={() => {
	  setIsToonModalOpen(false)
	  setIsStatsModalOpen(true)
          ReactGA.event({category: 'Modal', action: 'Toon Modal Opened'})
	}}
	handleSubmit={() => {
	  if (submitted === String(currentWeek)) {
		  setIsToonModalOpen(false)
	          setIsMoreThanOnce(true)
		  return setTimeout(() => {
			setIsMoreThanOnce(false)
		  }, ALERT_TIME_MS)
	  }
          setSubmitted(String(currentWeek))
	  setIsJustSubmitted(true)
	  setIsToonModalOpen(false)
          ReactGA.event({category: 'Button', action: 'Submitted a caption for week ' + Number(selectedWeek) })
          return setTimeout(() => { 
		setSuccessAlert('')
	  }, ALERT_TIME_MS)
	}}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          ReactGA.event({category: 'Button', action: 'Hit the share button'})
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      <div className="">
	<div>
	      <button
	        type="button"
	        className="mx-auto mt-8 flex justify-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
	        onClick={() => { 
		        ReactGA.event({category: 'Button', action: 'Join Discord'})
			window.open(discordUrl, "_blank")
		}}
	      >
		Join Star Trek: Discorderly
	      </button>
	</div>
	<div>
	      <button
	        type="button"
	        className="mx-auto mt-8 flex justify-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
	        onClick={() => { 
			setIsAboutModalOpen(true)
	                ReactGA.event({category: 'Modal', action: 'About Modal Opened'})
		}}
	      >
	        {ABOUT_GAME_MESSAGE}
	      </button>
	</div>
      </div>

      <Alert message="Can't submit more than once a week!" isOpen={isMoreThanOnce} />
      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert
        message={WORD_NOT_FOUND_MESSAGE}
        isOpen={isWordNotFoundAlertOpen}
      />
      <Alert 
	message={FAIL_MESSAGE[Math.floor(Math.random() * FAIL_MESSAGE.length)] + CORRECT_WORD_MESSAGE(solution)} 
	isOpen={isGameLost && !isStatsModalOpen && !isToonModalOpen && !isInfoModalOpen} 
	variant="warning"
      />
      <Alert 
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
      />
    </div>
  )
}

export default App
