export const TRACKING_ID = "G-QNF344ME1C"
export const GAME_TITLE = 'Trekle'
export const GAME_LOGO = 'Trekle.svg'
export const WIN_MESSAGES = [
		'Qapla!', 
		'Darmok and Jalad at Tanagra', 
		'Jolan Tru', 
		'Fascinating.', 
		'Made it so, eh?', 
		'Temba, at rest', 
		'Gave \'er all she’s got!',
		'There are FOUR LIGHTS!',
		'Damn your logic you pointy eared hobgoblin!',
		'Logic is the beginning of wisdom - not the end',
		'Let\'s fly!',
		'Hit it!',
		'The more complex the mind, the greater the need for the simplicity of play!',
		'The trial never ends',
		'You\'ve earned shore leave',
		'Second star on the right and straight on til morning',
		'I\'m beginning to think you could cure a rainy day']
export const GAME_COPIED_MESSAGE = 'Game copied to clipboard'
export const ABOUT_GAME_MESSAGE = 'About Trekle'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Not enough letters'
export const WORD_NOT_FOUND_MESSAGE = 'Word not found in the computer'
export const FAIL_MESSAGE = [
	'Shaka, when the walls fell.',
	'It\'s possible to commit no errors and still lose.  It makes us human.',
	'Highly illogical.',
	'I object to intellect without discipline.',
	'Things are only impossible til they’re not.',
	'You will never come up against a greater adversary than your own potential.',
	'The need for more research is clearly indicated.'
  ]
export const CORRECT_WORD_MESSAGE = (solution: string) => 
  ` The answer is ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Crew Performance Report'
export const GUESS_DISTRIBUTION_TEXT = 'Guess Distribution'
export const NEW_WORD_TEXT = 'New word in'
export const SHARE_TEXT = 'Share'
export const TOTAL_TRIES_TEXT = 'Total tries'
export const SUCCESS_RATE_TEXT = 'Success rate'
export const CURRENT_STREAK_TEXT = 'Current streak'
export const BEST_STREAK_TEXT = 'Best streak'
